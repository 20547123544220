/* Variables */
:root {
  --beerCount: ;
  --menuSectionCount: ;

}

/* Fonts */
@font-face {
  font-family: "EDS";
  src: url("./Assets/EverydaySansSerif-Regular.otf");
  /* src: url("./Assets/FredokaOne-Regular.ttf"); */
}

@font-face {
  font-family: 'Brixton';
  src: url('./Assets/BrixtonLn-Rg.otf')
  /* src: url('./Assets/Slabo27px-Regular.ttf') */
}

@font-face {
  font-family: 'Microbrew';
  src: url('./Assets/Microbrew-One.otf');
  src: url('./Assets/microbrew-one-webfont.ttf');
}

/* Globals/Misc */

body {
  margin: 0;
  background-size: cover;
  background-color: rgb(59, 59, 59);
  background-image: url('./Assets/slatebg.png');
  color: #ffffff;
}

h1 {
  font-size: 4rem;
  letter-spacing: .4rem;
  margin: 0px;
  font-family: 'Microbrew';
}

h2 {
  font-size: 2rem;
  margin: 0px;
  font-family: 'EDS';
}

p,
ul,
li {
  font-size: 2rem;
  margin: 0px;
  font-family: 'Brixton';
}

li {
  list-style: none;
}

.mainWrap {
  display: grid;
  grid-template-columns: 1fr .2fr 2fr .2fr 1.5fr;
}

.menuLabelWrap,
.tapLabelWrap {
  display: grid;
}

.menuLabel,
.tapLabel {
  max-width: 60%;
  margin: auto;
}

/* Menu */

.menu {
  width: 100%;
  height: 100vh;
}

.section2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 98vh;
  margin: auto 0;
  gap: 1.3rem;
}

.sectionWrap {
  display: grid;
  grid-template-columns: 1fr 4fr;
  text-align: right;
  background-color: rgba(59, 59, 59, 0.746);
}

.sectionName {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  padding: 1.5rem;
  margin: 1.5rem auto;
  background-color: rgba(143, 143, 143, 0.26);
}

.itemsWrap {
  margin: auto 0;
  padding-right: 1.5rem;
}

.optionWrap {
  background-color: rgba(71, 71, 71, 0.541);
  margin: 1.5rem 0;
}

.optionName {
  padding-right: 10rem;
}

.optionWrap ul li * {
  display: inline-block;
  padding: 0 1rem;
  letter-spacing: .2rem;
  margin: auto 0;
}

.optionWrap ul li :nth-child(1) {
  font-size: 1.4rem;
  margin: auto 0;
}

.optionWrap ul li {
  display: grid;
  padding: 0 1rem;
  letter-spacing: .2rem;
  grid-template-columns: minmax(10%, 20fr) 1fr 5fr;
}

.noOptionsWrap {
  display: grid;
  grid-template-columns: 20fr 1fr 3fr;
}

.noOptionsWrap h2,
.noOptionsWrap p {
  padding: 0 1rem;
  letter-spacing: .2rem;
  margin: auto 0;
}

/* Taplist */

.section3 {
  height: 100vh;
  display: grid;
  overflow: hidden;
}

.ferrisWrap2 {
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  row-gap: 1rem;
}

.beerWrap,
.beerSubwrap1,
.beerSubwrap2 {
  display: grid;
}

.beerWrap {
  grid-template-columns: 1fr 4fr;
  background-color: rgba(59, 59, 59, 0.746);
}

.beerName {
  font-size: 5rem;
  letter-spacing: .3rem;
  padding-left: 2rem;
}

.beerBrewer,
.beerStyle {
  margin: auto 0;
  text-align: right;
}

.beerBrewer {
  font-family: 'Microbrew';
}

.spacer {
  margin: auto;
  text-align: center;
}

.beerLocation,
.beerABV {
  margin: auto 0;
  text-align: left;
}

.beerStyle {
  font-size: 2rem;
}

.beerLocation {
  font-family: 'Microbrew';
}

.beerInfo {
  display: grid;
  grid-template-rows: repeat(4 1fr);
}

.beerSubwrap1,
.beerSubwrap2 {
  grid-template-columns: 6fr 1fr 6fr;
  background-color: rgba(71, 71, 71, 0.541);
}

.beerSubwrap2 {
  background-color: rgba(143, 143, 143, 0.26);
}

.beerLabelImg {
  margin: auto;
  /* height: 10rem; */
  width: 90%;
  padding: 1rem 0;

}

/* Slideshow */

.igImg {
  width: 100%;
}

.ferrisWrap,
.ferrisWrap2 {
  height: 100vh;
  overflow-y: hidden;
}